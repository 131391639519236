import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            salutation: 'Frau',
            firstName: '',
            lastName: '',
            email: '',
            tel: '',
            street: '',
            houseNumber: '',
            zip: '',
            location: ''
        };
    },
    actions,
    mutations,
    getters
}
