<template>
  <div class="dark">
    <label>
      <input
          type="radio"
          :value="value"
          :checked="modelValue === value"
          @change="$emit('update:modelValue', $event.target.value)"
      >
      <span class="design"></span>
      <span class="text">{{ value }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: ['modelValue', 'value'],
  emits: ['update:modelValue']
}
</script>

<style scoped lang="scss">
.dark {
  --primary: hsl(23, 54%, 31%);
  --other: hsl(0, 0%, 0%);

  font-family: Open Sans, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dark label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  position: relative;
}

.dark input {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.design {
  width: 1rem;
  height: 1rem;

  border: 1px solid var(--other);
  border-radius: 100%;
  margin-right: 10px;

  position: relative;
}

.design::before,
.design::after {
  content: "";
  display: block;

  width: inherit;
  height: inherit;

  border-radius: inherit;

  position: absolute;
  transform: scale(0);
  transform-origin: center center;
}

.design:before {
  background: var(--primary);
  opacity: 0;
  transition: .3s;
}

.design::after {
  background: var(--primary);
  opacity: .4;
  transition: .6s;
}

.text {
  color: var(--other);
}

.dark input:checked+.design::before {
  opacity: 1;
  transform: scale(.6);
}

.dark input:hover+.design,
.dark input:focus+.design {
  border: 1px solid var(--primary);
}

.dark input:hover+.design:before,
.dark input:focus+.design:before {
  background: var(--primary);
}

.dark input:hover~.text {
  color: var(--other);
}

.dark input:focus+.design::after,
.dark input:active+.design::after {
  opacity: .1;
  transform: scale(2.2);
}
</style>
