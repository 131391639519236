<template>
  <header>
    <img src="../assets/img/fundamento-logo.png" alt="Fundamento Bodentechnik Logo" @click="$router.push({ name: 'bodenrechnerApp' })">
    <h1>Der Bodenrechner der Uwe Walter Gruppe</h1>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

img {
  width: 15rem;
  margin-left: 3%;
  cursor: pointer;
  user-select: none;
}

h1 {
  margin: 0;
  padding: 2rem;
  color: white;
  background-color: $color-primary;
  text-align: center;
  font-family: $font-family-secondary;
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.7em;
  }
}
</style>
