<template>
  <base-button>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <path fill="currentColor" fill-rule="evenodd" d="M16,0L48,32,16,64V0Z"/>
    </svg>
    <slot>zurück</slot>
  </base-button>
</template>

<style scoped lang="scss">
svg {
  margin-right: 0.5em;
  transform: rotate(180deg);
}
</style>
