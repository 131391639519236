<template>
  <div class="img-container">
    <img src="@/assets/img/icons/fehler.png" alt="">
  </div>
  <div class="section-title"><h2>Es ist ein Fehler aufgetreten.</h2></div>

  <div class="result">
    <h3>Bitte versuchen Sie es noch einmal oder kontaktieren Sie uns</h3>
  </div>

  <div class="nav-buttons-container">
    <back-button @click="redirect">zurück</back-button>
    <next-button @click="mailto">Kontakt</next-button>
  </div>
</template>

<script>
import backButton from '@/components/buttons/BackButton';
import nextButton from '@/components/buttons/NextButton';

export default {
  name: 'ErrorView',
  components: {
    backButton,
    nextButton
  },
  methods: {
    redirect() {
      this.$router.replace({ name: 'bodenrechnerApp' });
    },
    mailto() {
      location.href = 'mailto:kontakt@eeh-digital.de';
    }
  }
}
</script>

<style scoped lang="scss">
.result {
  max-width: 45rem;
  padding: 1rem;
  text-align: center;
  box-sizing: border-box;

  h3 {
    font-weight: 600;
    font-size: 16px;
  }

  p {
    margin: 0;
    font-size: 11px;
    font-weight: 600;

    &.result__title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &.total-price {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }

    &.result__info {
      margin-top: 1rem;

      a {
        color: black;
      }
    }
  }
}

.img-container {
  margin-top: 10%;
}

.section-title {

  h2 {
    font-size: 27px;
    font-weight: bold;
  }
}
</style>
