<template>
  <form @submit.prevent="onSubmit">
    <div class="form__topic form__salutation">
      <radio-button value="Frau" v-model="contact.salutation"></radio-button>
      <radio-button value="Herr" v-model="contact.salutation"></radio-button>
      <radio-button value="Keine Angabe" v-model="contact.salutation"></radio-button>
    </div>

    <div class="form__topic">
      <label for="first-name">Name</label>
      <div class="form__name">
        <input type="text" id="first-name" placeholder="Vorname" required v-model.trim="contact.firstName">
        <input type="text" id="last-name" placeholder="Nachname" required v-model.trim="contact.lastName">
      </div>
    </div>

    <div class="form__topic">
      <label for="email">E-Mail Adresse</label>
      <input type="email" id="email" required v-model.trim="contact.email">
    </div>

    <div class="form__topic">
      <label for="tel">Telefonnummer (optional)</label>
      <input type="tel" id="tel" v-model.trim="contact.tel">
    </div>

    <div class="form__topic form__topic--address">
      <label for="street">Adresse</label>
      <div class="form__topic--address__row1">
        <input type="text" id="street" placeholder="Straße" required v-model.trim="contact.street">
        <input type="text" id="house-number" placeholder="Haus-Nr." required v-model.trim="contact.houseNumber">
      </div>
      <div class="form__topic--address__row2">
        <input type="text" id="zip" placeholder="PLZ" required v-model.trim="contact.zip">
        <input type="text" id="location" placeholder="Ort" required v-model.trim="contact.location">
      </div>
    </div>

    <div class="form__topic privacy-policy" @click="checkboxIsChecked = !checkboxIsChecked">
      <checkbox v-model:is-checked="checkboxIsChecked"></checkbox>
      <div class="privacy-policy-info">
        <router-link :to="{ name: 'privacyPolicy' }" target="_blank" @click.stop>Datenschutzbestimmungen</router-link>
        gelesen und akzeptiert?
      </div>
    </div>

    <div class="form__topic form__topic--info">
      Nach Ihrer Anfrage zum Bodenpreis erhalten Sie keinen Anruf von uns.<br>
      Ganz unverbindlich und ohne Verpflichtung.
    </div>

    <button type="submit" ref="formSubmitButton" hidden></button>
  </form>
</template>

<script>
import RadioButton from '@/components/buttons/RadioButton';
import Checkbox from '@/components/buttons/Checkbox';

export default {
  name: 'ContactForm',
  components: {
    RadioButton,
    Checkbox
  },
  emits: ['onSubmit'],
  data() {
    return {
      contact: {
        salutation: 'Frau',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        street: '',
        houseNumber: '',
        zip: '',
        location: ''
      },
      checkboxIsChecked: false
    };
  },
  methods: {
    submit() {
      this.$refs.formSubmitButton.click();
    },
    onSubmit() {
      this.$store.dispatch('contact/setContact', this.contact);
      this.$emit('onSubmit');
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

form {
  margin-top: 2rem;
  padding: 2.2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);

  .form__topic {
    & + .form__topic {
      margin-top: 2rem;
    }

    label {
      font-weight: bold;
    }

    &.form__salutation {
      display: flex;
      gap: 2rem;

      @media (max-width: 420px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    input {
      @extend %base-input;
      border-radius: 4px;
    }

    input::placeholder {
      color: #b6bfd7;
    }

    input[type=email] {
      margin-top: 0.5rem;
    }

    input[type=tel] {
      margin-top: 0.5rem;
    }

    .form__name {
      display: flex;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    &.form__topic--address div {
      display: grid;
      gap: 0.5rem;
      margin-top: 0.5rem;

      &.form__topic--address__row1 {
        grid-template-columns: 75% auto;
      }

      &.form__topic--address__row2 {
        margin-top: 0.5rem;
        grid-template-columns: auto 75%;
      }
    }

    &.privacy-policy {
      color: black;
      display: flex;
      gap: 1rem;
      align-items: center;

      .privacy-policy-info {
        font-size: 0.875rem;
        cursor: default;
        user-select: none;

        a {
          color: black;

          &:hover {
            color: black;
          }
        }
      }
    }

    &.form__topic--info {
      font-size: 0.875rem;
      font-weight: bold;
    }
  }
}
</style>
