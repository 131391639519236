import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './axios.config';
import '@/assets/styles/main.scss';
import BaseButton from '@/components/buttons/BaseButton';
import BackButton from '@/components/buttons/BackButton';
import NextButton from '@/components/buttons/NextButton';

const app = createApp(App).use(store)

app.component('BaseButton', BaseButton);
app.component('BackButton', BackButton);
app.component('NextButton', NextButton);

app.use(router).mount('#app');
