import { createStore } from 'vuex';
import contact from './contact';
import house from './house';

export default createStore({
  modules: {
    contact,
    house
  }
});
