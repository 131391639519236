function isValidRoom(room) {
    const requiredRoomProperties = [
        'id',
        'length',
        'width',
        'oldFlooringMaterial',
        'oldLayingType',
        'newFlooringMaterial',
        'newLayingType',
        'baseboard',
        'price',
        'priceText'
    ];
    const roomProperties = Object.keys(room);

    return `${requiredRoomProperties}` === `${roomProperties}`;
}

module.exports = { isValidRoom };
