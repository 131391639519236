<template>
  <div class="progress-bar" :style="{ width: percent }"></div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['percent']
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

.progress-bar {
  width: 0;
  height: 0.4rem;
  background-color: $color-secondary;
  align-self: flex-start;
  transition: width 1s ease-in-out;
}
</style>
