<template>
  <div class="section-title"><h2>Wie sehen die Räume aus?</h2></div>

  <form @submit.prevent="onSubmitRoomsForm">
    <room-card
        v-for="(room, index) in rooms"
        :key="room.id"
        :room="room"
        :room-index="index"
        :show-delete-room-button="rooms.length > 1"
        @update-room="updateRoom($event, index)"
        @delete-room="deleteRoom(index)"
    ></room-card>
    <button type="submit" ref="formSubmitButton" hidden></button>
  </form>

  <base-button class="add-room-button" :disabled="rooms.length >= MAX_ROOM_COUNT" @click="addRoom">
    +&nbsp; Raum hinzufügen
  </base-button>

  <div class="nav-buttons-container">
    <back-button @click="$emit('displaySection', 'section1')"></back-button>
    <next-button @click="$refs.formSubmitButton.click()"></next-button>
  </div>
</template>

<script>
import RoomCard from '@/components/RoomCard';
const { MAX_ROOM_COUNT } = require('../../../constants');
const { getNextRoomId } = require('@/helpers');

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Section2',
  components: {
    RoomCard
  },
  emits: ['displaySection'],
  data() {
    return {
      rooms: [],
      MAX_ROOM_COUNT
    };
  },
  created() {
    this.addRoom();
  },
  methods: {
    addRoom() {
      this.rooms.push({
        id: getNextRoomId(this.rooms),
        length: '',
        width: '',
        oldFlooringMaterial: '',
        oldLayingType: '',
        newFlooringMaterial: '',
        newLayingType: '',
        baseboard: '',
        price: null,
        priceText: null
      });
    },
    updateRoom(updatedRoom, roomIndex) {
      this.rooms[roomIndex] = updatedRoom;
    },
    deleteRoom(roomIndex) {
      this.rooms.splice(roomIndex, 1);
    },
    onSubmitRoomsForm() {
      this.$store.dispatch('house/setRooms', this.rooms);
      this.$emit('displaySection', 'section3');
    }
  }
}
</script>

<style scoped lang="scss">
form {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (max-width: 825px) {
    justify-content: center;
  }
}

.add-room-button {
  margin-top: 3rem;
}
</style>
