function getValidatedFloorCount(newFloorCount, oldFloorCount) {
    // Remove non-numeric chars and zeros. Keep numbers between 1 - 9
    newFloorCount = newFloorCount.replace(/[^1-9]/g, '');
    // Replace `oldFloorCount` by `newFloorCount`
    newFloorCount = newFloorCount.replace(oldFloorCount, '');

    return newFloorCount.substr(0, 1);
}

function getValidatedDimension(newDimension, oldDimension) {
    const filteredDimension = getFilteredDimension(newDimension);

    if (!isDimensionValid(filteredDimension)) { return oldDimension; }
    return filteredDimension;
}

function getFilteredDimension(dimension) {
    // Replace first `.` by `,` and remove all non-numeric chars and commas.
    return dimension.replace('.', ',').replace(/[^\d|,]/g, '');
}

function isDimensionValid(dimension) {
    // Is dimension in format `12,34`
    return dimension === '' || /^[1-9]\d?(,\d{0,2})?$/.test(dimension);
}

function getAutocompletedDimension(dimension) {
    if (dimension.length === 1) { return `${dimension},00`; } // 1
    if (dimension.length === 2 && dimension[1] !== ',') { return `${dimension},00`; } // 12
    if (dimension.length === 2 &&dimension[1] === ',') { return `${dimension}00`; } // 1,
    if (dimension.length === 3 && dimension[2] === ',') { return `${dimension}00`; } // 12,
    if (dimension.length === 3 && dimension[1] === ',') { return `${dimension}0`; } // 1,2
    if (dimension.length === 4) { return `${dimension}0`; } // 12,3
    return dimension; // 12,34
}

module.exports = { getValidatedFloorCount, getValidatedDimension, getAutocompletedDimension };
