<template>
  <footer>
    <div class="footer-links">
      <router-link :to="{ name: 'imprint' }" target="_blank">Impressum</router-link>&nbsp;|&nbsp;
      <router-link :to="{ name: 'privacyPolicy' }" target="_blank">Datenschutz</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

footer {
  padding: 2rem;
  background-color: $color-primary;
  flex-shrink: 0;

  .footer-links {
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;

    a {
      color: white;
      text-decoration: none;
    }
  }
}
</style>
