<template>
  <div :class="['room', { 'show-room-properties': showRoomProperties }]">
    <div class="room-header" @click="showRoomProperties = !showRoomProperties">
      <div class="room-number">Raum {{ roomIndex + 1}}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <path fill="currentColor" fill-rule="evenodd" d="M16,0L48,32,16,64V0Z"/>
      </svg>
    </div>

    <div class="room-properties">
      <div class="room-property-row room-dimensions">
        <div>
          <div class="room-property-label">Länge</div>
          <div>{{ room.length }} m</div>
        </div>
        <div>
          <div class="room-property-label">Breite</div>
          <div>{{ room.width }} m</div>
        </div>
      </div>

      <div class="room-property-row room-flooring" v-if="removeOldFloor">
        <div>
          <div class="room-property-label">Alter Bodenbelag</div>
          <div>{{ room.oldFlooringMaterial }}</div>
        </div>
        <div>
          <div class="room-property-label">Verlegeart</div>
          <div>{{ room.oldLayingType }}</div>
        </div>
      </div>

      <div class="room-property-row room-flooring">
        <div>
          <div class="room-property-label">Neuer Bodenbelag</div>
          <div>{{ room.newFlooringMaterial }}</div>
        </div>
        <div>
          <div class="room-property-label">Verlegeart</div>
          <div>{{ room.newLayingType }}</div>
        </div>
      </div>

      <div class="room-property-row">
        <div>
          <div class="room-property-label">Fußleiste</div>
          <div>{{ room.baseboard }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isValidRoom } from '@/helpers/prop-validations';

export default {
  name: 'RoomOverviewItem',
  props: {
    room: {
      type: Object,
      required: true,
      validator(room) {
        return isValidRoom(room);
      }
    },
    roomIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showRoomProperties: false
    };
  },
  computed: {
    removeOldFloor() {
      return this.$store.getters['house/getHouse'].removeOldFloor === 'Ja';
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

.room {
  height: min-content;
  border-radius: $border-radius-primary;
  box-shadow: $box-shadow-primary;

  &:hover .room-header {
    background-color: #f3f5f8;
  }

  &.show-room-properties {
    .room-header {
      background-color: #f3f5f8;
      border-radius: $border-radius-primary $border-radius-primary 0 0;
    }

    .room-properties {
      display: block;
    }

    svg {
      transform: rotate(270deg) !important;
    }
  }

  .room-header {
    padding: 0.5rem $room-padding;
    border-radius: $border-radius-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 385px;

    .room-number {
      font-size: 1.375rem;
      font-weight: bold;
    }

    svg {
      width: 1rem;
      height: auto;
      color: gray;
      transform: rotate(90deg);
    }

    @media (max-width: 1000px) {
      width: 250px;
    }

    @media (max-width: 768px) {
      width: 60vw;
    }
  }

  .room-properties {
    width: 385px;
    padding: 1.5rem $room-padding;
    background-color: #ffffff;
    border-radius: 0 0 $border-radius-primary $border-radius-primary;
    display: none;

    .room-property-row {
      & + .room-property-row {
        margin-top: 1rem;
      }

      &.room-dimensions {
        display: flex;
        gap: 1rem;
      }

      &.room-flooring {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
      }

      .room-property-label {
        font-weight: bold;
      }
    }

    @media (max-width: 1000px) {
      width: 250px;
    }

    @media (max-width: 768px) {
      width: 60vw;
    }
  }
}
</style>
