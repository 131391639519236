<template>
  <div class="house-card">
    <div class="house-card__img-container">
      <img :src="require(`@/assets/img/house-cards/${imgFileName}`)" :alt="imgAlt">
    </div>
    <div class="house-card__description">
      <slot>{{ description }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HouseCard',
  props: {
    imgFileName: {
      type: String,
      required: true
    },
    imgAlt: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

.house-card {
  width: 15em;
  height: 17em;
  background-color: white;
  font-size: 1rem;
  border-radius: 8px;
  box-shadow: $box-shadow-house-card;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr;
  cursor: pointer;
  user-select: none;

  &:hover, &:focus-visible {
    outline: 2px solid $color-primary;
    outline-offset: -2px;
  }

  &.house-card--no-hover {
    cursor: default;
    outline: none;
    outline-offset: unset;
  }

  .house-card__img-container {
    min-height: 0; /* Prevents image from expanding grid item */
    padding: 1.5em;
    background-color: $color-primary;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;


    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
    }
  }

  .house-card__description {
    font-size: 1.375em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  @media (max-width: 543px) {
    width: 13.2em;
  }
}


@media (max-width: 480px) {
  .house-card {
    width: 95%;
    height: 6.25em;
    display: flex;

    .house-card__description {
      padding-left: 20px;
    }

    .house-card__img-container {
      width: 52px;
    }
  }
}
</style>
