<template>
  <div class="loading-bar"></div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

.loading-bar {
  width: 100%;
  height: 0.4rem;
  position: relative;
  overflow: hidden;
  align-self: flex-start;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent 50%, $color-secondary 70%, $color-secondary 80%, transparent);
    position: absolute;
    transform: translateX(-100%);
    animation: loading 1.25s infinite linear;

    @keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
</style>
