<template>
  <base-button>
    <slot>weiter</slot>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <path fill="currentColor" fill-rule="evenodd" d="M16,0L48,32,16,64V0Z"/>
    </svg>
  </base-button>
</template>

<style scoped lang="scss">
svg {
  margin-left: 0.5em;
}
</style>
