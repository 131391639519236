<template>
  <button><slot></slot></button>
</template>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

button {
  padding: 0.7em 3em;
  color: white;
  background-color: $color-primary;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: $card-border-radius;
  box-shadow: $box-shadow-base-button;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:hover:not(:active, :disabled), &:focus-visible {
    background-color: $color-secondary;
    outline: none;
  }

  &:disabled {
    background-color: #b6bfd7;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 1em;
    width: 70vw;
    justify-content: center;
  }
}
</style>
