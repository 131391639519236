const axios = require('axios');
const { getAppCheckToken } = require('@/firebase');

axios.defaults.baseURL = process.env.VUE_APP_ENV === 'PRODUCTION' ?
    'https://europe-west3-bodenrechner.cloudfunctions.net/api' :
    'http://localhost:5001/bodenrechner/europe-west3/api';
axios.interceptors.request.use(async config => {
    config.headers['X-Firebase-AppCheck'] = await getAppCheckToken();
    return config;
});
