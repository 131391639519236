const { initializeApp } = require('firebase/app');
const { initializeAppCheck, ReCaptchaV3Provider, getToken } = require('firebase/app-check');
//const { getAnalytics } = require('firebase/analytics');

const firebaseConfig = {
    apiKey: "AIzaSyBVrQYzXqBwMqHyqpqLLsW3qRlLUkaZL-s",
    authDomain: "bodenrechner.firebaseapp.com",
    projectId: "bodenrechner",
    storageBucket: "bodenrechner.appspot.com",
    messagingSenderId: "341845671338",
    appId: "1:341845671338:web:d052501ef4158607a1ffd3",
    measurementId: "G-JPE8Z7WPBD"
};
const firebaseApp = initializeApp(firebaseConfig);

if (process.env.VUE_APP_ENV === 'DEVELOPMENT') {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
}

const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6LeS4LcgAAAAAAA9wOV-dqz8Qay0i0M4qxlKa-Rj'),
    isTokenAutoRefreshEnabled: true
});

async function getAppCheckToken() {
    const appCheckTokenResponse = await getToken(appCheck, false);
    return appCheckTokenResponse.token;
}

//const firebaseAnalytics = getAnalytics(firebaseApp); TODO

module.exports = { getAppCheckToken };
