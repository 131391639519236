<template>
  <div class="img-container">
    <img src="@/assets/img/icons/mail.png" alt="">
  </div>
  <div class="section-title"><h2>Fast geschafft. Nur noch ein Schritt.</h2></div>

  <div class="result">
    <h3>Bitte bestätigen Sie, dass Sie der Inhaber der angegebenen E-Mail Adresse sind. Klicken Sie hierzu einfach auf den Button in der Mail,
    die Sie gerade von uns erhalten haben.</h3>
    <p class="result__info">
      Schauen Sie dazu bitte ggf. auch in Ihren Spam/Junk Ordner.
    </p>
  </div>

  <div class="nav-buttons-container">
    <base-button @click="redirect">zurück zur Website</base-button>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Section5',
  methods: {
    redirect() {
      location.href = 'https://fundamento-bodentechnik.de';
    }
  }
}
</script>

<style scoped lang="scss">
.result {
  max-width: 45rem;
  padding: 1rem;
  text-align: center;
  box-sizing: border-box;

  h3 {
    font-weight: 600;
    font-size: 16px;
  }

  p {
    margin: 0;
    font-size: 11px;
    font-weight: 600;

    &.result__title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &.total-price {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }

    &.result__info {
      margin-top: 1rem;

      a {
        color: black;
      }
    }
  }
}

.img-container {
  margin-top: 10%;
}

.section-title {

  h2 {
    font-size: 27px;
    font-weight: bold;
  }
}
</style>
