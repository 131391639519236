<template>
  <progress-bar :percent="progressBarPercent" v-if="showProgressBar"></progress-bar>
  <loading-bar v-else-if="showLoadingBar"></loading-bar>

  <section>
    <section1 v-if="displayedSection === 'section1'" @display-section="displaySection"></section1>
    <keep-alive>
      <section2 v-if="displayedSection === 'section2'" @display-section="displaySection"></section2>
    </keep-alive>
    <section3 v-if="displayedSection === 'section3'" @display-section="displaySection"></section3>
    <keep-alive>
      <section4
          v-if="displayedSection === 'section4'"
          @display-section="displaySection"
          @hide-progress-bar="showProgressBar = false"
          @set-show-loading-bar="showLoadingBar = $event">
      </section4>
    </keep-alive>
    <section5 v-if="displayedSection === 'section5'"></section5>
  </section>
</template>

<script>
import ProgressBar from '@/components/ProgressBar';
import LoadingBar from '@/components/LoadingBar';
import Section1 from '@/components/Section1';
import Section2 from '@/components/Section2';
import Section3 from '@/components/Section3';
import Section4 from '@/components/Section4';
import Section5 from '@/components/Section5';

export default {
  name: 'BodenrechnerApp',
  components: {
    ProgressBar,
    LoadingBar,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5
  },
  data() {
    return {
      progressBarPercent: '0%',
      showProgressBar: true,
      showLoadingBar: false,
      displayedSection: 'section1',
      showModal: false
    };
  },
  watch: {
    displayedSection(displayedSection) {
      switch (displayedSection) {
        case 'section1': return this.progressBarPercent = `${1/4 * 100}%`;
        case 'section2': return this.progressBarPercent = `${2/4 * 100}%`;
        case 'section3': return this.progressBarPercent = `${3/4 * 100}%`;
        case 'section4': return this.progressBarPercent = `${4/4 * 100}%`;
        default: this.progressBarPercent = '100%';
      }
    }
  },
  mounted() {
    setTimeout(() => this.progressBarPercent = `${1/4 * 100}%`, 0);
  },
  methods: {
    displaySection(section) {
      this.displayedSection = section;
    }
  }
}
</script>

<style scoped lang="scss">
section {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 590px) {
    padding: 0 1rem;
  }
}
</style>
