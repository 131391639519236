<template>
  <the-header></the-header>
  <main>
    <router-view/>
  </main>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from './components/TheHeader';
import TheFooter from './components/TheFooter';

export default {
  components: {
    TheHeader,
    TheFooter
  }
}
</script>

<style scoped lang="scss">
main {
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto; /* Footer setting */
}
</style>
