<template>
  <dialog ref="modal" class="test" @click.self="$emit('close')">
    <div class="modal-content">
      <delete-button class="modal-close-btn" @click="$emit('close')"></delete-button>
      <FlooringModal></FlooringModal>
    </div>
  </dialog>
</template>

<script>
import FlooringModal from "@/components/FlooringModal";
import deleteButton from "@/components/buttons/DeleteButton";
export default {
  name: 'BaseModal',
  components: {
    FlooringModal,
    deleteButton
  },
  mounted() {
    this.$refs.modal.showModal();
  }
}
</script>

<style scoped lang="scss">
dialog {
  padding: 0;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 0 1em rgb(0 0 0 / 0.3);

  &::backdrop {
    background-color: rgba(182, 191, 215, 0.4);
  }

  .modal-content {
    $modal-content-padding: 1rem;
    padding: $modal-content-padding;

    .modal-close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 480px) {
    width: 70%;
  }
}

/* Transitions */
$transition: all 0.25s;
$transform: scale(0.95);
$opacity: 0;

.v-enter-from {
  transform: $transform;
  opacity: $opacity;

  &::backdrop {
    opacity: $opacity;
  }
}

.v-enter-active {
  transition: $transition;

  &::backdrop {
    transition: $transition;
  }
}

.v-leave-active {
  transition: $transition;

  &::backdrop {
    transition: $transition;
  }
}

.v-leave-to {
  transform: $transform;
  opacity: $opacity;

  &::backdrop {
    opacity: $opacity;
  }
}
</style>
