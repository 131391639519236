<template>
  <h1>Bodenbeläge</h1>
<div class="two-rows">
  <div class="flooringBox">
    <img src="../assets/img/teppich.jpg">
    <h2>Teppich</h2>
  </div>
  <div class="flooringBox">
    <img src="../assets/img/fliese.jpg">
    <h2>Fliese</h2>
  </div>
  <div class="flooringBox">
    <img src="../assets/img/laminat.jpg">
    <h2>Laminat</h2>
  </div>
  <div class="flooringBox">
    <img src="../assets/img/fertigparkett.jpg">
    <h2>Fertigparkett</h2>
  </div>
  <div class="flooringBox">
    <img src="../assets/img/echtparkett.jpg">
    <h2>Echtparkett</h2>
  </div>
  <div class="flooringBox">
    <img src="../assets/img/pvc.jpg">
    <h2>PVC</h2>
  </div>
  <div class="flooringBox">
    <img src="../assets/img/estrich.jpg">
    <h2>Estrich</h2>
  </div>
</div>
</template>

<script>
export default {
  name: "FlooringModal"
}
</script>

<style scoped lang="scss">
  .two-rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    img {
      width: 185px;
      height: 105px;
      border-radius: 10px;
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr;

      img {
        width: 100%;
      }
    }
  }
  h1 {
    margin: 0 0 20px 0;
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
    margin: 0;
  }
</style>