<template>
  <div class="section-title"><h2>Muss bestehender Boden entfernt werden?</h2></div>

  <div class="cards-container">
    <house-card
        img-file-name="neubau-ja.png"
        img-alt="TODO"
        description="Ja"
        tabindex="1"
        @click="selectHouseCard('Ja')">
    </house-card>
    <house-card
        img-file-name="neubau-nein.png"
        img-alt="TODO"
        description="Nein"
        tabindex="2"
        @click="selectHouseCard('Nein')">
    </house-card>
  </div>
</template>

<script>
import HouseCard from '@/components/HouseCard';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Section1',
  components: {
    HouseCard
  },
  emits: ['displaySection'],
  methods: {
    selectHouseCard(removeOldFloor) {
      this.$store.dispatch('house/setRemoveOldFloor', removeOldFloor);
      this.$emit('displaySection', 'section2');
    }
  }
}
</script>

<style scoped lang="scss">
.cards-container {
  margin-top: 2rem;
}
</style>
