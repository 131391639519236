<template>
  <div class="rooms-overview">
    <rooms-overview-item
        v-for="(room, index) in rooms"
        :key="room.id"
        :room="room"
        :room-index="index"
    ></rooms-overview-item>
  </div>
</template>

<script>
import RoomsOverviewItem from '@/components/RoomsOverviewItem';

export default {
  name: 'RoomsOverview',
  components: {
    RoomsOverviewItem
  },
  computed: {
    rooms() {
      return this.$store.getters['house/getRooms'];
    }
  }
}
</script>

<style scoped lang="scss">
.rooms-overview {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
</style>
