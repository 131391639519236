<template>
  <div class="section-title"><h2>Schließen Sie jetzt Ihren persönlichen Bodenrechner ab!</h2></div>

  <contact-form ref="contactForm" @on-submit="onSubmitContactForm"></contact-form>

  <div class="nav-buttons-container">
    <back-button :disabled="navButtonsDisabled" @click="$emit('displaySection', 'section3')"></back-button>
    <next-button :disabled="navButtonsDisabled" @click="$refs.contactForm.submit()"></next-button>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm';
const axios = require('axios');

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Section4',
  components: {
    ContactForm
  },
  emits: ['displaySection', 'hideProgressBar', 'setShowLoadingBar'],
  data() {
    return {
      navButtonsDisabled: false
    };
  },
  methods: {
    async onSubmitContactForm() {
      this.navButtonsDisabled = true;
      this.$emit('hideProgressBar');
      this.$emit('setShowLoadingBar', true);
      scroll(0, 0);

      try {
        const house = this.$store.getters['house/getHouse'];
        const contact = this.$store.getters['contact/getContact'];

        await axios.post('/submit', { house, contact });

        this.$emit('setShowLoadingBar', false);
        this.$emit('displaySection', 'section5');
      } catch (err) {
        await this.$router.replace({ name: 'error' });
      }
    }
  }
}
</script>
