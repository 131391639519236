<template>
  <div class="imprint">
    <h1>Impressum</h1>

    <h2>Angaben gemäß § 5 TMG</h2>
    <p>Fundamento Bodentechnik GmbH<br />
      Meinbergstr. 69<br />
      44269 Dortmund</p>

    <p><strong>Vertreten durch:</strong><br />
      Uwe Walter</p>

    <h2>Kontakt</h2>
    <p>Telefon: +49 2 31 / 42 78 57 05<br />
      Telefax: +49 2 31 / 42 78 57 09<br />
      E-Mail: buero@fundamento-bodentechnik.de</p>

    <h2>EU-Streitschlichtung</h2>
    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

    <h2>Verbraucher&shy;streitbeilegung/&shy;Universal&shy;schlichtungsstelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    <h2>Umsetzung:</h2>
    <h3>Konzept &amp; Realisation</h3>
    <p>Kaleidoskop Marketing-Service GmbH<br />Matthias Eigel<br />Schwätzgässle 9<br />74321 Bietigheim-Bissingen<br />Tel.: 07142/77 05-0<br /><a href="https://www.kaleidoskop.de">www.kaleidoskop.de</a></p>
    <h3>Design &amp; Entwicklung</h3>
    <p>EEH-Digital<br /><a href="https://eeh-digital.de">www.eeh-digital.de</a></p>

    <div class="btn-container">
      <back-button @click="$router.push({ name: 'bodenrechnerApp' })">zurück zum Bodenrechner</back-button>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Imprint',
}
</script>

<style scoped lang="scss">
.imprint {
  max-width: 58rem;
  margin-top: 3rem;
  padding: 0 2rem;

  h1 {
    margin: 0 0 2rem;
  }
}

button {
  margin-top: 3rem;
}

@media (max-width: 420px) {
  .btn-container {
    display: flex;
    justify-content: center;
  }
}
</style>
