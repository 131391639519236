<template>
  <div class="room-card">
    <div class="room-card__header">
      <div class="room-number">Raum {{ roomIndex + 1}}</div>
      <delete-button v-if="showDeleteRoomButton" @click="$emit('deleteRoom')"></delete-button>
    </div>

    <div class="room-property-row room-dimensions">
      <div>
        <div class="room-property-label"><label for="length">Länge in m</label></div>
        <input class="dimensions-input" type="text" id="length" placeholder="0" autocomplete="off" required
               v-model.trim="updatedRoom.length"
               @blur="autocompleteDimension('length')"
        >
      </div>
      <div>
        <div class="room-property-label"><label for="width">Breite in m</label></div>
        <input class="dimensions-input" type="text" id="width" placeholder="0" autocomplete="off" required
               v-model.trim="updatedRoom.width"
               @blur="autocompleteDimension('width')"
        >
      </div>
    </div>

    <div class="room-property-row" v-if="removeOldFloor">
      <div>
        <header>
          <div class="room-property-label">Alter Bodenbelag</div>

          <div class="info">
            <img src="@/assets/img/icons/bodenrechner-info.svg" alt="Info Icon" class="info-icon" @click="showModal = !showModal">
            <teleport to="body">
              <transition><base-modal v-if="showModal" @close="showModal = false">Text</base-modal></transition>
            </teleport>
          </div>
        </header>

        <div>
          <select required v-model="updatedRoom.oldFlooringMaterial">
            <option value="" disabled selected hidden>auswählen...</option>
            <option value="Teppich">Teppich</option>
            <option value="Fliese">Fliese</option>
            <option value="Laminat">Laminat</option>
            <option value="Fertigparkett">Fertigparkett</option>
            <option value="Echtparkett">Echtparkett</option>
            <option value="PVC">PVC</option>
            <option value="Estrich">Estrich</option>
          </select>
        </div>
      </div>

      <div>
        <div class="room-property-label">Verlegeart</div>
        <select required :disabled="oldLayingTypeIsPreselected" v-model="updatedRoom.oldLayingType">
          <option value="" disabled selected hidden>auswählen...</option>
          <option value="verklebt">Verklebt</option>
          <option value="lose verlegt">Lose verlegt</option>
          <option value="weiß ich nicht">Weiß ich nicht</option>
        </select>
      </div>
    </div>

    <div class="room-property-row full-row">
      <div class="tile-note" v-if="updatedRoom.oldFlooringMaterial === 'Fliese'">
        Wir empfehlen in diesem Fall eine Überarbeitung mit einem Design PVC. Aufgrund der geringen Aufbauhöhe
        von ca. 5mm muss die Fliese in den meisten Fällen nicht rausgestemmt werden.
        Bei der Auswahl anderer neuer Bodenbeläge entstehen Zusatzkosten.
      </div>
    </div>

    <div class="room-property-row">
      <div>
        <header>
          <div class="room-property-label">Neuer Bodenbelag</div>

          <div class="info">
            <img src="@/assets/img/icons/bodenrechner-info.svg" alt="Info Icon" class="info-icon" @click="showModal = !showModal">
            <teleport to="body">
              <transition><base-modal v-if="showModal" @close="showModal = false">Text</base-modal></transition>
            </teleport>
          </div>
        </header>

        <div>
          <select required v-model="updatedRoom.newFlooringMaterial">
            <option value="" disabled selected hidden>auswählen...</option>
            <option value="Teppich">Teppich</option>
            <option value="Laminat">Laminat</option>
            <option value="Fertigparkett">Fertigparkett</option>
            <option value="Design PVC">Design PVC</option>
            <option value="Parkettrenovierung" v-if="!removeOldFloor">Parkettrenovierung</option>
          </select>
        </div>
      </div>
      <div>
        <div class="room-property-label">Verlegeart</div>
        <select required :disabled="newLayingTypeIsPreselected" v-model="updatedRoom.newLayingType">
          <option value="" disabled selected hidden>auswählen...</option>
          <option value="verklebt">Verklebt</option>
          <option value="lose verlegt">Lose verlegt</option>
          <option value="weiß ich nicht">Weiß ich nicht</option>
        </select>
      </div>
      <div>
        <div class="room-property-label">Fußleiste</div>
        <select required v-model="updatedRoom.baseboard">
          <option value="" disabled selected hidden>auswählen...</option>
          <option value="Weiß">Weiß</option>
          <option value="Bodenoptik">Bodenoptik</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteButton from './buttons/DeleteButton';
import baseModal from "@/components/BaseModal";
const { isValidRoom } = require('../helpers/prop-validations.js');
const { getValidatedDimension, getAutocompletedDimension } = require('@/helpers/input-validations');

export default {
  name: 'RoomCard',
  components: {
    DeleteButton,
    baseModal
  },
  props: {
    room: {
      type: Object,
      required: true,
      validator(room) {
        return isValidRoom(room);
      }
    },
    roomIndex: {
      type: Number,
      required: true
    },
    showDeleteRoomButton: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    updateRoom(updatedRoom) {
      return isValidRoom(updatedRoom)
    },
    deleteRoom: null
  },
  data() {
    return {
      updatedRoom: {
        id: this.room.id,
        length: this.room.length,
        width: this.room.width,
        oldFlooringMaterial: this.room.oldFlooringMaterial,
        oldLayingType: this.room.oldLayingType,
        newFlooringMaterial: this.room.newFlooringMaterial,
        newLayingType: this.room.newLayingType,
        baseboard: this.room.baseboard,
        price: this.room.price,
        priceText: this.room.priceText
      },
      showModal: false
    };
  },
  watch: {
    updatedRoom: {
      deep: true,
      handler() {
        this.$emit('updateRoom', this.updatedRoom);
      }
    },
    'updatedRoom.length'(newLength, oldLength) {
      this.updatedRoom.length = getValidatedDimension(newLength, oldLength);
    },
    'updatedRoom.width'(newWidth, oldWidth) {
      this.updatedRoom.width = getValidatedDimension(newWidth, oldWidth);
    },
    oldLayingTypeIsPreselected() {
      this.updatedRoom.oldLayingType = 'verklebt';
    },
    newLayingTypeIsPreselected() {
      this.updatedRoom.newLayingType = 'lose verlegt';
    }
  },
  computed: {
    removeOldFloor() {
      return this.$store.getters['house/getHouse'].removeOldFloor === 'Ja';
    },
    oldLayingTypeIsPreselected() {
      const material = this.updatedRoom.oldFlooringMaterial;
      return material === 'Fliese' || material === 'Estrich';
    },
    newLayingTypeIsPreselected() {
      const material = this.updatedRoom.newFlooringMaterial;
      return material === 'Laminat' || material === 'Parkettrenovierung';
    }
  },
  methods: {
    autocompleteDimension(dimensionType) {
      this.updatedRoom[dimensionType] = getAutocompletedDimension(this.updatedRoom[dimensionType]);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

.room-card {
  width: 51.25rem;
  padding: $room-padding;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);

  .room-card__header {
    display: flex;
    justify-content: space-between;

    .room-number {
      font-size: 1.375rem;
      font-weight: bold;
    }
  }

  .room-property-row {
    margin-top: 2rem;
    display: inline-flex;
    gap: 1.5rem;

    header {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      .room-property-label {
        margin: 0;
      }

      .info {
        display: flex;
        align-items: center;

        .info-icon {
          width: 1.5rem;
          position: absolute;
          cursor: pointer;
        }
      }
    }

    .room-property-label {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    select {
      width: 14.375rem;
      padding: 0.5em;
      color: black;
      font-size: 1rem;
      background: white url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><path fill="%23b6bfd7" fill-rule="evenodd" transform="rotate(90, 32, 32)" d="M16,0L48,32,16,64V0Z"/></svg>') no-repeat right content-box;
      background-size: 1rem;
      border: 2px solid lightgray;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      outline: none;
      /* Arrow */
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:focus {
        border-color: $color-primary;
      }

      &:invalid {
        color: #b6bfd7;
      }

      &:disabled {
        cursor: not-allowed;
      }

      option {
        color: black;
      }
    }

    &.room-dimensions {
      display: grid;
      grid-template-columns: 10rem 1fr;
      gap: 1.5rem;

      input {
        width: 10rem;
        padding: 0.5em;
        font-size: 1rem;
        border: 2px solid lightgray;
        border-radius: 8px;
        box-sizing: border-box;
        outline: none;

        &:focus {
          border-color: $color-primary;
        }
        &::placeholder{
          color: #b6bfd7;
        }
      }
    }
  }
}

@media (max-width: 810px) {
  .room-card {
    width: 415px;
    padding: 1rem;
  }
  .full-row {
    grid-template-columns: 1fr!important;
  }
  .room-property-row {
    display: grid!important;
    grid-template-columns: 11rem 1fr;

    select {
      width: 100%!important;
    }

    &.room-dimensions {
      grid-template-columns: 11rem 1fr!important;

      input {
        width: 100%!important;
      }
    }
  }
}

@media (max-width: 480px) {
  .room-card {
    width: 90vw!important;
  }
  .room-property-row {
    grid-template-columns: 1fr;

    &.room-dimensions {
      grid-template-columns: 1fr!important;
    }
  }
}
</style>
