<template>
  <button>
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
      <path fill="currentColor" fill-rule="evenodd" d="M10.225,10.225a34.957,34.957,0,0,1,49.2,0l196.8,196.8,196.805-196.8a34.79,34.79,0,0,1,49.2,49.2l-196.8,196.8L502.237,453.036a34.79,34.79,0,0,1-49.2,49.2l-196.8-196.8L59.426,502.237a34.79,34.79,0,0,1-49.2-49.2l196.8-196.8-196.8-196.8A34.957,34.957,0,0,1,10.225,10.225Z"/>
    </svg>
  </button>
</template>

<style scoped lang="scss">
button {
  padding: 0.8rem;
  background-color: #724624;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-self: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }

  svg {
    width: 0.6rem;
    height: auto;
    color: white;
  }
}
</style>
