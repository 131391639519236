import { createRouter, createWebHistory } from 'vue-router';
import BodenrechnerApp from '@/views/BodenrechnerApp';
import DoubleOptIn from '@/views/DoubleOptIn';
import Imprint from '@/views/Imprint';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import ErrorView from '@/views/ErrorView';

const routes = [
  {
    name: 'bodenrechnerApp',
    path: '/',
    component: BodenrechnerApp
  },
  {
    name: 'doubleOptIn',
    path: '/double-opt-in',
    component: DoubleOptIn
  },
  {
    name: 'imprint',
    path: '/impressum',
    component: Imprint
  },
  {
    name: 'privacyPolicy',
    path: '/datenschutz',
    component: PrivacyPolicy
  },
  {
    name: 'error',
    path: '/error',
    component: ErrorView
  },
  {
    name: 'notFound',
    path: '/:notFound(.*)*',
    redirect: { name: 'bodenrechnerApp' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
