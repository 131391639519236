<template>
  <loading-bar v-if="requestStatus === RequestStatus.LOADING"></loading-bar>
  <p v-if="requestStatus === RequestStatus.LOADING">Bitte haben Sie einen Moment Geduld...</p>
  <p v-else-if="requestStatus === RequestStatus.FAILED">Ups, da ist etwas schiefgelaufen :(</p>

  <section v-else>
    <header>
      <img src="@/assets/img/icons/check-mark.png" alt="">
      <div class="section-title"><h2>Sie haben Ihren persönlichen Bodenrechner erfolgreich ausgefüllt.</h2></div>
    </header>

    <p>
      Sie erhalten von uns schnellstmöglich eine Kostenschätzung zu Ihrem Beispielprojekt an Ihre E-Mail Adresse.
    </p>
    <p style="font-size: 0.75rem">
      Sollten Sie in den nächsten Tagen keine E-Mail von uns erhalten, prüfen Sie bitte auch Ihren Spam-/Junk Ordner
    </p>

    <div class="nav-buttons-container">
      <base-button @click="redirect">zurück zur Website</base-button>
    </div>
  </section>
</template>

<script>
import LoadingBar from '@/components/LoadingBar';
const axios = require('axios');
const { RequestStatus } = require('@/helpers/enums');

export default {
  name: 'DoubleOptIn',
  components: {
    LoadingBar
  },
  data() {
    return {
      requestStatus: RequestStatus.LOADING,
      RequestStatus
    };
  },
  created() {
    this.confirmDoubleOptIn();
  },
  methods: {
    async confirmDoubleOptIn() {
      try {
        const uuid = this.$route.query.uuid;

        await axios.post('/double-opt-in', { uuid });
        this.requestStatus = RequestStatus.SUCCESSFUL;
      } catch (err) {
        await this.$router.replace({ name: 'error' });
      }
    },
    redirect() {
      location.replace('https://fundamento-bodentechnik.de');
    }
  }
}
</script>

<style scoped lang="scss">
section {
  margin: 0 2rem;
  text-align: center;

  header {
    margin: 6rem 0 2.5rem;
  }

  p {
    font-weight: 600;
  }
}
</style>

