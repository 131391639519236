<template>
  <div :class="['checkbox', { checked: isChecked }]">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="currentColor" d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/>
    </svg>
    <input type="checkbox" required :checked="isChecked" @change="$emit('update:isChecked', $event.target.checked)">
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Checkbox',
  props: ['isChecked'],
  emits: ['update:isChecked']
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/main.scss';

.checkbox {
  $border-width: 2px;

  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125em;
  border: $border-width solid lightgray;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  display: grid;
  place-items: center;
  cursor: pointer;

  &:hover, &:focus-within {
    border-color: $color-primary;
  }

  &.checked {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover, &:focus-within {
      background-color: $color-secondary;
      border-color: $color-secondary;
    }

    svg {
      visibility: visible;
    }
  }

  &.invalid {
    border-color: red;
  }

  svg {
    width: 100%;
    height: auto;
    color: white;
    visibility: hidden;
  }

  input {
    width: 2px;
    height: 2px;
    margin: 0;
    outline: 0;
    position: absolute;
    bottom: -$border-width;
    opacity: 0;
    z-index: -1;
  }
}
</style>
