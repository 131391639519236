export default {
    setContact(state, contact) {
        state.salutation = contact.salutation;
        state.firstName = contact.firstName;
        state.lastName = contact.lastName;
        state.email = contact.email;
        state.tel = contact.tel;
        state.street = contact.street;
        state.houseNumber = contact.houseNumber;
        state.zip = contact.zip;
        state.location = contact.location;
    }
}
