<template>
  <div class="section-title"><h2>Räume</h2></div>

  <rooms-overview></rooms-overview>

  <div class="nav-buttons-container">
    <back-button @click="$emit('displaySection', 'section2')"></back-button>
    <next-button @click="$emit('displaySection', 'section4')"></next-button>
  </div>
</template>

<script>
import RoomsOverview from '@/components/RoomsOverview';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Section3',
  components: {
    RoomsOverview
  },
  emits: ['displaySection']
}
</script>

<style scoped lang="scss">
</style>
